import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const API_BASE_URL = 'https://api.bigtime-tw.com';

interface Label {
    label_id: number;
    label: string;
    price: number;
}

interface LabelGroup {
    group_id: number;
    group: string;
    label_responses: Label[] | null;
}

const LabelManagementPage: React.FC = () => {
    const [labelGroups, setLabelGroups] = useState<LabelGroup[]>([]);
    const [loading, setLoading] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newLabel, setNewLabel] = useState({
        groupId: 0,
        title: '',
        price: 0,
    });

    const fetchLabelGroups = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/label`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const result = await response.json();
            if (result.success && Array.isArray(result.data)) {
                setLabelGroups(
                    result.data.map((group: LabelGroup) => ({
                        ...group,
                        label_responses: group.label_responses || [],
                    }))
                );
            } else {
                setLabelGroups([]);
            }
        } catch {
            alert('Failed to fetch label groups');
            setLabelGroups([]);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteLabel = async (labelId: number) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/label/${labelId}`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            });
            const result = await response.json();
            if (result.success) {
                alert('Label deleted successfully');
                fetchLabelGroups(); // Refresh the label groups
            } else {
                alert(result.message || 'Failed to delete label');
            }
        } catch {
            alert('Failed to delete label');
        }
    };

    const handleAddGroup = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/label/group`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ group_name: newGroupName }),
            });
            const result = await response.json();
            if (result.success) {
                alert('Group added successfully');
                setNewGroupName('');
                fetchLabelGroups();
            } else {
                alert(result.message || 'Failed to add group');
            }
        } catch {
            alert('Failed to add group');
        }
    };

    const handleAddLabel = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/label`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    group_id: newLabel.groupId,
                    title: newLabel.title,
                    price: newLabel.price,
                }),
            });
            const result = await response.json();
            if (result.success) {
                alert('Label added successfully');
                setIsModalOpen(false);
                setNewLabel({ groupId: 0, title: '', price: 0 });
                fetchLabelGroups();
            } else {
                alert(result.message || 'Failed to add label');
            }
        } catch {
            alert('Failed to add label');
        }
    };

    useEffect(() => {
        fetchLabelGroups();
    }, []);

    return (
        <div className="d-flex">
            {/* Sidebar */}
            <div className="sidebar bg-light p-3" style={{ width: '250px' }}>
                <h5 className="mb-4">Navigation</h5>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link to="/user-list" className="nav-link">
                            User List
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/photographer-list" className="nav-link">
                            Photographer List
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/label-management" className="nav-link active">
                            Label Management
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="content p-4" style={{ flex: 1 }}>
                <h3>Label Management</h3>

                {/* Add Group */}
                <div className="mb-3">
                    <input
                        type="text"
                        placeholder="New Group Name"
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                        className="form-control mb-2"
                    />
                    <button onClick={handleAddGroup} className="btn btn-primary">
                        Add Group
                    </button>
                </div>

                {/* Add Label */}
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="btn btn-secondary mb-3"
                >
                    Add Label
                </button>

                {/* Label Groups Table */}
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <table className="table table-striped">
                        <thead className="thead-light">
                        <tr>
                            <th>Group</th>
                            <th>Labels</th>
                        </tr>
                        </thead>
                        <tbody>
                        {labelGroups.map((group) => (
                            <tr key={group.group_id}>
                                <td>{group.group}</td>
                                <td>
                                    <ul>
                                        {(group.label_responses || []).map((label) => (
                                            <li key={label.label_id}>
                                                {label.label} (${label.price}){' '}
                                                <button
                                                    onClick={() => handleDeleteLabel(label.label_id)}
                                                    className="btn btn-danger btn-sm"
                                                >
                                                    Delete
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>

            {/* Add Label Modal */}
            {isModalOpen && (
                <div
                    className="modal"
                    style={{
                        display: 'block',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Label</h5>
                                <button
                                    onClick={() => setIsModalOpen(false)}
                                    className="close"
                                    style={{ border: 'none', background: 'transparent' }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label>Group</label>
                                    <select
                                        className="form-control"
                                        value={newLabel.groupId}
                                        onChange={(e) =>
                                            setNewLabel({ ...newLabel, groupId: Number(e.target.value) })
                                        }
                                    >
                                        <option value={0}>Select a Group</option>
                                        {labelGroups.map((group) => (
                                            <option key={group.group_id} value={group.group_id}>
                                                {group.group}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newLabel.title}
                                        onChange={(e) =>
                                            setNewLabel({ ...newLabel, title: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Price</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={newLabel.price}
                                        onChange={(e) =>
                                            setNewLabel({ ...newLabel, price: Number(e.target.value) })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={handleAddLabel}
                                    className="btn btn-primary"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={() => setIsModalOpen(false)}
                                    className="btn btn-secondary"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LabelManagementPage;
