import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = 'https://api.bigtime-tw.com';

const LoginPage: React.FC = () => {
    const [account, setAccount] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/auth/login_admin`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ account, password }),
            });
            const result = await response.json();

            if (result.success) {
                localStorage.setItem('token', result.data); // Store the token
                navigate('/user-list'); // Redirect to User List page
            } else {
                alert(result.message || 'Login failed');
            }
        } catch {
            alert('Failed to login');
        }
    };

    return (
        <div className="container py-5">
            <form onSubmit={handleLogin} className="bg-light p-4 rounded shadow w-50 mx-auto">
                <h2 className="text-center mb-4">Admin Login</h2>
                <input
                    type="text"
                    placeholder="Account"
                    value={account}
                    onChange={(e) => setAccount(e.target.value)}
                    className="form-control mb-3"
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control mb-3"
                />
                <button type="submit" className="btn btn-primary btn-block">
                    Login
                </button>
            </form>
        </div>
    );
};

export default LoginPage;
