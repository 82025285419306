import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import UserListPage from './UserListPage';
import PhotographerListPage from './PhotographerListPage';
import LabelManagementPage from './LabelManagementPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/user-list" element={<UserListPage />} />
                <Route path="/photographer-list" element={<PhotographerListPage />} />
                <Route path="/label-management" element={<LabelManagementPage />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;
