import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const API_BASE_URL = 'https://api.bigtime-tw.com';

interface PhotographerData {
    id: number;
    email: string;
    phone: string;
    firstname: string;
    lastname: string;
    national_id: string;
    live_city: string;
    status: 'PENDING' | 'APPROVED' | 'REJECTED';
    file_url: string | null;
}

const PhotographerListPage: React.FC = () => {
    const [photographerData, setPhotographerData] = useState<PhotographerData[]>([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');
    const [selectedPhotographer, setSelectedPhotographer] = useState<PhotographerData | null>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const fetchPhotographerData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');

            const params = new URLSearchParams({
                ...(keyword && { keyword }),
                ...(status && { status }),
            });

            const response = await fetch(`${API_BASE_URL}/bo/member/photographer_verify?${params}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const result = await response.json();
            if (result.success) {
                setPhotographerData(result.data.records);
            }
        } catch {
            alert('Failed to fetch photographer data');
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = async (userId: number, status: 'APPROVED' | 'REJECTED') => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/bo/member/photographer_verify/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ user_id: userId, status }),
            });
            const result = await response.json();
            if (result.success) {
                alert(`Status updated to ${status}`);
                fetchPhotographerData(); // Refresh data after status update
            } else {
                alert(result.message || 'Failed to update status');
            }
        } catch {
            alert('Failed to update status');
        }
    };

    const handleImageClick = (imageUrl: string) => {
        setSelectedImage(imageUrl); // Set selected image for modal
    };

    const closeImageModal = () => {
        setSelectedImage(null); // Close image modal
    };

    useEffect(() => {
        fetchPhotographerData();
    }, []);

    return (
        <div className="d-flex">
            {/* Sidebar */}
            <div className="sidebar bg-light p-3" style={{ width: '250px' }}>
                <h5 className="mb-4">Navigation</h5>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link to="/user-list" className="nav-link">
                            User List
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/photographer-list" className="nav-link active">
                            Photographer List
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/label-management" className="nav-link">
                            Label Management
                        </Link>
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="content p-4" style={{ flex: 1 }}>
                <h3>Photographer List</h3>

                {/* Filters */}
                <div className="form-inline mb-3">
                    <input
                        type="text"
                        className="form-control mr-3"
                        placeholder="Search by keyword"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    <select
                        className="form-control mr-3"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="">All Status</option>
                        <option value="PENDING">Pending</option>
                        <option value="APPROVED">Approved</option>
                        <option value="REJECTED">Rejected</option>
                    </select>
                    <button onClick={fetchPhotographerData} className="btn btn-primary">
                        Search
                    </button>
                </div>

                {/* Photographer Table */}
                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th>ID</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>Status</th>
                                <th>Actions</th>
                                <th>Detail</th>
                            </tr>
                            </thead>
                            <tbody>
                            {photographerData.map((photographer) => (
                                <tr key={photographer.id}>
                                    <td>{photographer.id}</td>
                                    <td>{photographer.firstname}</td>
                                    <td>{photographer.lastname}</td>
                                    <td>{photographer.email}</td>
                                    <td>{photographer.phone}</td>
                                    <td>{photographer.live_city}</td>
                                    <td>
                      <span
                          className={`badge ${
                              photographer.status === 'APPROVED'
                                  ? 'badge-success'
                                  : photographer.status === 'PENDING'
                                      ? 'badge-warning'
                                      : 'badge-danger'
                          }`}
                      >
                        {photographer.status}
                      </span>
                                    </td>
                                    <td>
                                        {photographer.status === 'PENDING' && (
                                            <div className="btn-group">
                                                <button
                                                    onClick={() => handleStatusChange(photographer.id, 'APPROVED')}
                                                    className="btn btn-sm btn-success"
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    onClick={() => handleStatusChange(photographer.id, 'REJECTED')}
                                                    className="btn btn-sm btn-danger"
                                                >
                                                    Reject
                                                </button>
                                            </div>
                                        )}
                                        {photographer.status !== 'PENDING' && <span>No Actions</span>}
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => setSelectedPhotographer(photographer)}
                                            className="btn btn-sm btn-info"
                                        >
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {/* Details Modal */}
            {selectedPhotographer && (
                <div
                    className="modal"
                    style={{
                        display: 'block',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                    }}
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Photographer Details</h5>
                                <button
                                    onClick={() => setSelectedPhotographer(null)}
                                    className="close"
                                    style={{ border: 'none', background: 'transparent' }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <p><strong>First Name:</strong> {selectedPhotographer.firstname}</p>
                                    <p><strong>Last Name:</strong> {selectedPhotographer.lastname}</p>
                                    <p><strong>Email:</strong> {selectedPhotographer.email}</p>
                                    <p><strong>Phone:</strong> {selectedPhotographer.phone}</p>
                                    <p><strong>City:</strong> {selectedPhotographer.live_city}</p>
                                    <div>
                                        <strong>Files:</strong>
                                        <div className="d-flex flex-wrap mt-2">
                                            {selectedPhotographer.file_url && (
                                                <img
                                                    src={selectedPhotographer.file_url}
                                                    alt="File"
                                                    style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                                                    onClick={() => handleImageClick(selectedPhotographer.file_url!)} // Add `!` to assert it's not null
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => setSelectedPhotographer(null)}
                                    className="btn btn-secondary"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Image Modal */}
            {selectedImage && (
                <div
                    className="modal"
                    style={{
                        display: 'block',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                    }}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <img
                                    src={selectedImage}
                                    alt="Enlarged File"
                                    style={{ maxWidth: '100%', maxHeight: '80vh' }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button onClick={closeImageModal} className="btn btn-secondary">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PhotographerListPage;
